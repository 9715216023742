@use 'src/style/header';
@use 'src/style/reset';
@use 'src/style/label';
@use 'src/style/diagnostic-step';
@use 'src/style/step';
@use 'src/style/diagnostic-page';
@use 'src/style/button';
@use 'src/style/diagnostic';
@use 'src/style/loader';
@use 'src/style/logo';

html {
  font-size: 1px;
}

body {
  background: linear-gradient(180deg, #F7F7F7 0%, #F6F6F6 25.91%, #ECEDF1 65.19%, #E7E8EC 81.15%, #E3E4E9 100%) no-repeat;
  font-size: 14rem;
  font-family: "FuturaPT", sans-serif;
}

.diagnostic {
  min-height: 100vh;
}
